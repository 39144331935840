import axios from "axios";
import common from "../../api/common";
const axiosApiInstance = axios.create();

const refreshAccessToken = async () => {
  try {
    let data1 = {
      refresh_token: window.sessionStorage.getItem("refershToken")
    };
    const response = await axios.post(`${common.api_url}/get-cognito-token-from-refresh-token`, data1)
    return response.data;
  } catch {
    sessionStorage.clear();
    window.location.reload();
  }
};

axiosApiInstance.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("token");
  config.headers["Authorization"] = token ? `Bearer ${token}` : "";
  config.headers["Accept"] = "application/json";
  config.headers["Content-Type"] = "application/json"
  return config;
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const access_token = await refreshAccessToken();
      if (access_token && access_token.id_token) {
        sessionStorage.setItem("token", access_token.id_token.toString());
      }
      error.response.config.headers["Authorization"] =
        "Bearer " + access_token.access_token;
      return await axiosApiInstance(error.response.config);
    }

    return Promise.reject(error);
  }
);

export default axiosApiInstance;
