import common from "../../api/common";
import axios from "axios";
import { data } from "jquery";
import axiosApiInstance from "../auth/authService";

export const getCardList = async (customer_id) => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-card-lists`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ customer_id: customer_id }),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createNewCard = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/create-new-card`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createNewPaymentMethod = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/stripe-new-payment-method`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createNewPaymentMethodEp = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-customer-default-card`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const AddNewCard = async (data) => {
  var config = {
    method: "post",
    url: `${common.api_url}/create-stripe-card`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const getPaymentMethod = async (customer_id) => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-payment-method`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ customer_id: customer_id }),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data.data;
  } else {
    return null;
  }
};
export const creditToPatient = async (payload) => {
  var config = {
    method: "post",
    url: `${common.api_url}/create-credit-customer`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updatePaymentMethod = async (payload) => {
  var config = {
    method: "post",
    url: `${common.api_url}/update-customer-method`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const getSubscriptionStripe = async (subscription_id) => {
  var config = {
    method: "post",
    url: `${common.api_url}/stripe-get-subscription`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ subscription_id: subscription_id }),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const cancelSubscriptionStripe = async (subscription_id) => {
  var config = {
    method: "post",
    url: `${common.api_url}/stripe-cancel-subscription`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ subscription_id: subscription_id }),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const postPayment = async (payload) => {
  var config = {
    method: "post",
    url: `${common.api_url}/post-payment-charge`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const createSubscription = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/subscription-create`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const createTrialSubscription = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/subscription-create-trial`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createWeightTrialSubscription = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/subscription-weigthloss-payment`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createTestSubscription1 = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/create-payment-subcription-testing`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createinvoice = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/stripe-create-invoice`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createTestSubscription = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/create-payment-subcription`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateSubscriptionNew = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/stripe-update-subsription-saving`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createPaymentMethod = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/create-customer-applepay`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const getOpenLoopPaymentMethod = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/get-openloop-payment-method`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createPaymentBilling = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/create-payment-method`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const createPaymentBillingValue = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/create-billing-information`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const getPaymentBilling = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/get-billing-information`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const updateSubscription = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/update-customer-subscription`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const retriveSubscription = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/retrive-customer-subscription`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};
export const refundPayment = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/stripe-refund`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const refundSub = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/stripe-fetch-all`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  };
};




export const createAuthMethod = async (payload) => {

  var config = {
    method: "post",
    url: `${common.api_url}/create-payment-intent-auth`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(payload),
  };
  const resp = await axios(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  };
};


export const stripeUpdateCard = async (payload) => {
  var config = {
    method: "post",
    url: `${common.api_url}/stripe-update-customer-card`,
    data: JSON.stringify(payload),
  };
  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};



export const createStripeNewCard = async (payload) => {
  var config = {
    method: "post",
    url: `${common.api_url}/create-stripe-card`,
    data: JSON.stringify(payload),
  };
  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const getCustomerIdByEmail = async (payload) => {
  var config = {
    method: "post",
    url: `${common.api_url}/get-customer-id-by-email`,
    data: JSON.stringify(payload),
  };
  const resp = await axiosApiInstance(config);
  console.log(resp)
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};